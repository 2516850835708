import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { Card, CardSubtitle, CardText, CardBody } from "reactstrap"

import Layout from "../components/layout/layout2"
import SEO from "../components/seo"

const getImage = graphql`
  {
    fluid: file(relativePath: { eq: "barracuda_miami.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const AboutPage = () => {
  const data = useStaticQuery(getImage)
  return (
    <Layout title="O Ponto G++ e eu...">
      <SEO title="Sobre" />
      <Card>
        <div className="card-image mt-2">
          <Img fluid={data.fluid.childImageSharp.fluid} />
        </div>
        <CardBody>
          <CardSubtitle className="caption text-center">
            Barracuda, Miami - Cheers!
          </CardSubtitle>
          <div className="blog mt-2">
            <CardText>
              Comecei a desenvolver este site ainda experimentalmente, enquanto
              aprendia HTML, CSS, PHP, JavaScript e outras ferramentas para
              editoração na Web, servindo como um laboratório para testar as
              diversas técnicas a medida em que as ia dominando.
            </CardText>
            <CardText>
              Posteriormente, conheci diversos Sistemas de Gerenciamento de
              Conteúdo (CMS - do inglês Content Management System), como Drupal,
              Modx, Wordpress e, finalmente, geradores de páginas estáticas como
              Hugo, Jekyll, Grain e JBake. Passei a dispor, então, do
              conhecimento e das ferramentas necessárias para montar um site
              pessoal, no qual pudesse divulgar assuntos relacionados com os
              hobbies que mantive ao longo da vida. Durante muito tempo,
              utilizei o <em>framework</em> Hugo, para gerar as páginas
              estáticas do <em>Site</em>, mas mais recentemente passei a usar o{" "}
              <a
                href="https://pt-br.reactjs.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                React
              </a>{" "}
              para desenvolvimento, e acabei adotando o{" "}
              <a
                href="https://www.gatsbyjs.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gatsby
              </a>{" "}
              para sua geração.
            </CardText>
            <CardText>
              Os assuntos que você encontrará aqui são relacionados,
              primeiramente, com a programação de computadores, que venho
              estudando há muitos anos, desde que adquiri meu primeiro
              computador pessoal Apple II, em meados da década de 1980. Passei
              pelo Basic, dBase, C, C++, Delphi, C#, Java, JavaScript, HTML5,
              CSS3 tudo aprendido sozinho, como <em>hobby</em> mesmo.
            </CardText>
            <CardText>
              Acho que isso é um estímulo para aqueles milhares de jovens Geeks
              que estão entrando agora no mercado de trabalho. Com o acesso ao
              enorme cabedal de conhecimento disponibilizado pela Internet ao
              alcance das mãos, não há limites para até onde um indivíduo pode
              chegar com o esforço próprio.
            </CardText>
            <CardText>
              Outro assunto que focalizarei é a mecatrônica, versão moderna do
              meu antigo <em>hobby</em> de eletrônica, praticada desde os tempos
              de radioamadorismo, no princípio da década de 1980. Possuo as
              principais novidades modernas nesta área: Raspberry Pi e Arduíno.
              Como dizem, a gente nunca larga os brinquedos, eles só vão ficando
              cada vez mais caros…
            </CardText>
            <CardText>
              Aproveitarei para incluir também conteúdo sobre vários programas
              fantásticos de código aberto que venho usando e, finalmente, não
              faltará espaço para outra velha paixão: o sistema operacional
              Linux.
            </CardText>
            <CardText>
              Finalmente, tendo herdado do meu pai a paixão pela escrita, vou
              incluir no meu <em>Blog</em> crônicas e outros ensaios de minha
              lavra e alguns artigos dele, o Jornalista José Mendes Ribeiro,
              publicados nos jornais locais de Lorena, no interior paulista.
            </CardText>
            <CardText>
              Atualmente aposentado, sobra-me tempo para dedicação integral a
              esses <em>hobbies</em>, compartilhando tudo o que achar
              interessante aqui no Ponto G++, o ponto de encontro de velhos (e
              novos) Geeks.
            </CardText>
            <CardText>
              Grato pela visita. Espero que aproveite o material e volte sempre.
            </CardText>
          </div>
        </CardBody>
      </Card>
    </Layout>
  )
}

export default AboutPage
